import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "@sholdi/primitives/atoms/SVG";

export const Chevron = ({ open, disabled, className }) => (
  <SVG
    fill="none"
    className={twMerge(
      "fill-none h-2 w-3.5",
      !disabled ? "pointer" : "not-allowed",
      className,
    )}
    viewBox="0 0 14 8"
  >
    {!open && (
      <path
        d="M1 1L7 7L13 1"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    )}
    {open && (
      <path
        d="M1 7L7 1L13 7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    )}
  </SVG>
);

Chevron.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
};
