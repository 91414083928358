import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "@sholdi/primitives/atoms/SVG";

export const Plus = ({ open, disabled, className }) => (
  <SVG
    fill="none"
    className={twMerge(
      "fill-none w-5.5 h-5.5",
      !disabled ? "pointer" : "not-allowed",
      className,
    )}
    viewBox="0 0 22 22"
  >
    {!open && (
      <path
        d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    )}
    {open && (
      <path
        d="M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    )}
  </SVG>
);

Plus.propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
