import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { cva } from "class-variance-authority";
import { forwardRef } from "react";

import Text from "@sholdi/primitives/atoms/Text";
import getVariantChildStyles from "@sholdi/shared/helpers/getVariantChildStyles";
import Box from "@sholdi/primitives/atoms/Box";

const variants = {
  white: {
    wrapper: "w-full p-4 rounded shadow-xs bg-white",
    activator: "justify-between items-center",
    title: "font-heading leading-8 text-md",
    content: "mt-8",
  },
  filter: {
    activator: "flex-row-reverse justify-end items-center",
    wrapper: "mb-4",
    iconWrapper: "h-5",
    title: "text-sm ml-5.5",
    content: "mt-2",
  },
  adminSidebar: {
    wrapper: "shadow-none mb-4",
    activator: "justify-center pr-0 py-2",
    iconWrapper: "items-center",
    content: "mt-0 bg-transparent",
  },
  shopSettings: {
    wrapper: "",
    title: "text-sm",
  },
  adminFilter: {
    title: "text-xl font-medium px-4",
  },
};

const title = cva("font-heading", {
  variants: { variant: getVariantChildStyles(variants, "title") },
});

export const AccordionTitle = ({ variant, className, ...props }) => (
  <Text className={twMerge(className, title({ variant }))} {...props} />
);

AccordionTitle.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

const wrapper = cva("", {
  variants: { variant: getVariantChildStyles(variants, "wrapper") },
});

export const AccordionWrapper = ({ variant, className, ...props }) => (
  <Box
    className={twMerge("flex", wrapper({ variant }), className)}
    {...props}
  />
);

AccordionWrapper.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

const activatorClasses = {
  expanded: "pr-4",
  active: "bg-primary-lightest text-primary-main font-medium rounded-md",
};

const activator = cva("flex", {
  variants: { variant: getVariantChildStyles(variants, "activator") },
});

export const AccordionActivator = forwardRef(
  ({ variant, expanded, active, className, ...props }, ref) => (
    <div
      className={twMerge(
        expanded && variant === "adminSidebar" ? activatorClasses.expanded : "",
        active ? activatorClasses.active : "",
        className,
        activator({ variant }),
      )}
      {...props}
      ref={ref}
    />
  ),
);

AccordionActivator.displayName = "AccordionActivator";

AccordionActivator.propTypes = {
  variant: PropTypes.string,
  expanded: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
};

const iconWrapperClasses = {
  expanded: "inline-block",
};

const iconWrapper = cva("", {
  variants: { variant: getVariantChildStyles(variants, "iconWrapper") },
});

export const AccordionIconWrapper = ({
  variant,
  expanded,
  className,
  ...props
}) => (
  <div
    className={twMerge(
      expanded && variant === "adminSidebar" ? iconWrapperClasses.expanded : "",
      className,
      iconWrapper({ variant }),
    )}
    {...props}
  />
);

AccordionIconWrapper.propTypes = {
  variant: PropTypes.string,
  expanded: PropTypes.bool,
  className: PropTypes.string,
};

const content = cva("", {
  variants: { variant: getVariantChildStyles(variants, "content") },
});

export const AccordionContent = forwardRef(
  ({ variant, className, ...props }, ref) => (
    <div
      className={twMerge(className, content({ variant }))}
      ref={ref}
      {...props}
    />
  ),
);

AccordionContent.displayName = "AccordionContent";

AccordionContent.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};
