import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useMeasuredRef from "@sholdi/hooks/useMeasuredRef";
import { twMerge } from "tailwind-merge";

import { Chevron } from "./Chevron";
import { Plus } from "./Plus";

import {
  AccordionActivator,
  AccordionContent,
  AccordionIconWrapper,
  AccordionTitle,
  AccordionWrapper,
} from "./components";

const EXPAND_ICONS_MAP = {
  arrow: Chevron,
  plus: Plus,
};

const iconColors = {
  "gray.900": "text-gray-900",
  "primary.main": "text-primary-main",
};

const Accordion = ({
  disabled,
  title,
  children,
  withoutIndicator,
  variant = "white",
  iconType = "arrow",
  isInitiallyOpen,
  activator: Activator,
  activatorProps,
  titleProps,
  className,
  iconColor,
  customOpen,
  expanded,
  toggleOnArrow = false,
  ...props
}) => {
  const [open, setOpen] = useState(isInitiallyOpen);
  const { ref: measuredRef } = useMeasuredRef();

  const Icon = EXPAND_ICONS_MAP[iconType] ?? EXPAND_ICONS_MAP.arrow;

  useEffect(() => {
    setOpen(isInitiallyOpen);
  }, [isInitiallyOpen]);

  const expander = Activator ? (
    <Activator {...activatorProps} isOpen={open} />
  ) : (
    <AccordionTitle
      className={twMerge(
        "font-heading font-bold text-primary-text leading-8 px-4",
        titleProps?.className,
      )}
      variant={variant}
    >
      {title}
    </AccordionTitle>
  );

  const openAccordion = () => {
    if (customOpen) return;
    setOpen((previousValue) => !previousValue);
  };

  const disabledClass = !disabled ? "cursor-pointer" : "cursor-not-allowed";
  return (
    <AccordionWrapper
      aria-current={open}
      variant={variant}
      expanded={expanded?.toString()}
      className={twMerge(
        "flex-col relative w-full rounded-lg transition ease-in-out duration-75",
        className,
      )}
      {...props}
    >
      <AccordionActivator
        variant={variant}
        expanded={expanded}
        className={twMerge(
          "flex items-center justify-between w-full",
          disabledClass,
          activatorProps?.className,
        )}
        {...(!toggleOnArrow ? { onClick: openAccordion } : {})}
      >
        {expander}
        {!withoutIndicator && (
          <AccordionIconWrapper
            variant={variant}
            expanded={expanded}
            className={twMerge(disabledClass, iconColors[iconColor] || "")}
            {...(toggleOnArrow ? { onClick: openAccordion } : {})}
          >
            <Icon open={open} disabled={disabled} className="h-4" />
          </AccordionIconWrapper>
        )}
      </AccordionActivator>
      {open && (
        <div className="flex cursor-default transition-all ease-in-out duration-75">
          <AccordionContent
            ref={measuredRef}
            variant={variant}
            className="w-full"
          >
            {children}
          </AccordionContent>
        </div>
      )}
    </AccordionWrapper>
  );
};

Accordion.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  withoutIndicator: PropTypes.bool,
  disabled: PropTypes.bool,
  isInitiallyOpen: PropTypes.bool,
  iconType: PropTypes.string,
  activator: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.shape({}),
  ]),
  className: PropTypes.string,
  iconColor: PropTypes.string,
  customOpen: PropTypes.bool,
  toggleOnArrow: PropTypes.bool,
  activatorProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  expanded: PropTypes.bool,
  titleProps: PropTypes.shape({
    className: PropTypes.string,
  }),
};

Accordion.displayName = "Accordion";

export default Accordion;
