import debounce from 'lodash.debounce'
import isEqual from 'lodash.isequal'
import { useEffect, useState, useCallback } from 'react'
import { useCallbackRef } from 'use-callback-ref'

const getRect = element => {
  if (!element) {
    return {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
    }
  }

  return element.getBoundingClientRect()
}

const useMeasuredRef = () => {
  const [rect, setRect] = useState(getRect(null))

  const ref = useCallbackRef(null, node => setRect(getRect(node)))

  useEffect(() => {
    debounce(() => setRect(getRect(ref.current)), 2000)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current])

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return
    }

    // Update client rect
    setRect(previousRect => {
      if (!isEqual(previousRect, getRect(ref.current))) {
        return getRect(ref.current)
      }
      return previousRect
    })
  }, [ref])

  // uselayouteffect
  useEffect(() => {
    const element = ref.current
    if (!element) {
      return
    }

    handleResize()

    if (typeof ResizeObserver === 'function') {
      let resizeObserver = new ResizeObserver(() => handleResize())
      resizeObserver.observe(element)

      // eslint-disable-next-line consistent-return
      return () => {
        if (!resizeObserver) {
          return
        }

        resizeObserver.disconnect()
        resizeObserver = null
      }
    }
    // Browser support, remove freely
    window.addEventListener('resize', handleResize, { passive: true })

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('resize', handleResize)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  return { ref, rect }
}

export default useMeasuredRef
